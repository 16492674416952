<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :max-width="buttonMaxWidth"
        :max-height="buttonMaxHeight"
        v-bind="attrs"
        :disabled="btnDisable"
        v-on="on"
        class="ma-2"
        elevation="0"
        icon
        small
        @click.stop="$emit('onClick')"
      >
        <slot name="custom" v-if="!mode"> </slot>

        <v-icon v-else-if="mode == 'detail'" class="button-detail"
          >mdi-information-variant</v-icon
        >

        <v-icon v-else-if="mode == 'add'" class="button-detail"
          >mdi-plus</v-icon
        >

        <v-icon color="#e67e22" v-else-if="mode == 'edit'" class="button-detail"
          >mdi-circle-edit-outline</v-icon
        >

        <v-icon
          color="#c0392b"
          v-else-if="mode == 'set-password'"
          class="button-detail"
          >mdi-lock-reset</v-icon
        >
        <v-icon
          color="#FF5722"
          v-else-if="mode == 'delete'"
          class="button-detail"
          >mdi-delete</v-icon
        >

        <v-icon
          color="#34495e"
          v-else-if="mode == 'category'"
          class="button-detail"
          >mdi-account-edit</v-icon
        >
        <v-icon v-else class="button-detail">{{ buttonIcon }}</v-icon>
      </v-btn>
    </template>
    <span v-if="mode == 'detail'">Detil</span>
    <span v-else-if="mode == 'add'">Tambah Detail Plan</span>
    <span v-else-if="mode == 'edit'">Ubah {{ updateName }}</span>
    <span v-else-if="mode == 'set-password'">Set Password</span>
    <span v-else-if="mode == 'delete'">Hapus</span>
    <span v-else-if="mode == 'category'">Ubah Kategori</span>
    <span v-else>{{ buttonTooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    buttonMaxWidth: {
      type: String,
      required: false,
      default: "",
    },
    buttonMaxHeight: {
      type: String,
      required: false,
      default: "",
    },
    btnDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonIcon: {
      type: String,
      required: false,
      default: "",
    },
    buttonTooltip: {
      type: String,
      required: false,
      default: "",
    },
    updateName: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * Supported mode:
     * edit, delete
     */
    mode: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["onClick", "disable"],
};
</script>
